import React, { useState } from 'react'

interface PlayerBoxProps {
  name: string
  description: string
}

const PlayerBox = ({ name, description }: PlayerBoxProps) => {
  const [descriptionOpen, setDescriptionOpen] = useState(false)

  return (
    <div>
      <div
        style={{
          maxWidth: descriptionOpen ? '500px' : 'max-content',
          margin: '12px 0',
          padding: '8px 12px',
          boxShadow: '0 1px 4px 0 rgba(0,0,0,0.25)',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
        onClick={() => setDescriptionOpen((prevState) => !prevState)}
      >
        <div>{name}</div>
        {descriptionOpen && (
          <>
            <hr />
            <div style={{ whiteSpace: 'pre-line' }}>{description}</div>
          </>
        )}
      </div>
    </div>
  )
}

export default PlayerBox
