import React, { useState, useEffect } from 'react'

import PlayerBox from './PlayerBox'

const TEAMS = [
  'Boston Celtics',
  'Brooklyn Nets',
  'New York Knicks',
  'Philadelphia 76ers',
  'Toronto Raptors',
  'Chicago Bulls',
  'Cleveland Cavaliers',
  'Detroit Pistons',
  'Indiana Pacers',
  'Milwaukee Bucks',
  'Denver Nuggets',
  'Minnesota Timberwolves',
  'Oklahoma City Thunder',
  'Portland Trail Blazers',
  'Utah Jazz',
  'Golden State Warriors',
  'LA Clippers',
  'Los Angeles Lakers',
  'Phoenix Suns',
  'Sacramento Kings',
  'Atlanta Hawks',
  'Charlotte Hornets',
  'Miami Heat',
  'Orlando Magic',
  'Washington Wizards',
  'Houston Rockets',
  'Memphis Grizzlies',
  'New Orleans Pelicans',
  'San Antonio Spurs',
]

const SORTED_TEAMS = TEAMS.sort()

SORTED_TEAMS.unshift('Dallas Mavericks')

function App() {
  const [selectedTeam, setSelectedTeam] = useState(SORTED_TEAMS[0])

  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    fetch(
      `https://teey93jp6h.execute-api.us-east-1.amazonaws.com/prod/mavs?team_name=${selectedTeam}`
    )
      .then((res) => res.json())
      .then((res) => {
        setData(res)
        setLoading(false)
      })
  }, [selectedTeam])

  const matches = data?.matches || []
  const players = (data?.players || []) as {
    name: string
    description?: string
  }[]

  const clippedMatches = matches.slice(0, 8)

  return (
    <div className="App">
      <div>
        <select
          onChange={(event) => setSelectedTeam(event.target.value)}
          value={selectedTeam}
        >
          {TEAMS.map((team) => (
            <option value={team} key={team}>
              {team}
            </option>
          ))}
        </select>
      </div>

      {loading ? (
        'loading...'
      ) : (
        <>
          <div>
            <h2 style={{ marginBottom: 0 }}>Matches</h2>
            <div>
              {clippedMatches.map((m: string) => (
                <div key={m}>
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const teamShortName = m.split(/[, ]/)[1]
                      const teamFullName = SORTED_TEAMS.find((team) =>
                        team.includes(teamShortName)
                      )
                      if (teamFullName) {
                        setSelectedTeam(teamFullName)
                      }
                    }}
                  >
                    {m}
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div>
            <h2 style={{ marginBottom: 0 }}>Players</h2>
            <div>
              {players.map(({ name, description }) => (
                <PlayerBox name={name} description={description || ''} />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default App
